import axios from 'axios';

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    sendDebugInfo({rootGetters}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(rootGetters.getUrl('/api/log-debug'), payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  }
}
