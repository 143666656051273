<template>
  <h4 v-if="canSeeUsers && show_active_cases === true" class="centered margins no-margin-bottom margin-top-large">
    Alle aktiven Betreuungsf&auml;lle
  </h4>
  <h4 v-else-if="canSeeUsers && show_active_cases === false" class="centered margins no-margin-bottom margin-top-large">
    Alle abgeschlossenen Betreuungsf&auml;lle
  </h4>
  <h4
    v-else-if="schoolType === 'SFZ' && is_admin === false
      || userRole === 'MSD-MitarbeiterIn'
      || userRole === 'TherapeutIn'"
    class="centered margins no-margin-bottom margin-top-large"
  >
    Ihre Sch&uuml;lerInnen
  </h4>
  <h4 v-else class="centered margins no-margin-bottom margin-top-large">
    Alle Sch&uuml;lerInnen
  </h4>
  <BaseSearchBar
    v-if="!searchBarIsHidden"
    @closeIconClicked="searchBarIsHidden = true; sortIconIsHidden = !sortIconIsHidden;"
  >
    <input
      v-model="searchQuery"
      type="text"
      placeholder="Nach SchülerIn suchen"
    >
  </BaseSearchBar>
  <BaseSortDropdown
    v-if="!sortIconIsHidden"
    :number-of-content-properties="numberOfContentPropertiesSortDropdown"
  >
    <template #dropdown_item_1>
      <sui-dropdown-item
        text="Sortieren nach: "
        class="text_bold"
      />
    </template>
    <template #dropdown_item_2>
      <sui-dropdown-item
        v-if="canSeeUsers"
        text="Nachname (SchülerIn) aufsteigend"
        @click="sortAllSchueler('asc','schueler_name','schueler_vorname')"
      />
      <sui-dropdown-item
        v-else
        text="Nachname aufsteigend"
        @click="sortAllSchueler('asc','schueler_name','schueler_vorname')"
      />
    </template>
    <template #dropdown_item_3>
      <sui-dropdown-item
        v-if="canSeeUsers"
        text="Nachname (SchülerIn) absteigend"
        @click="sortAllSchueler('desc','schueler_name','schueler_vorname')"
      />
      <sui-dropdown-item
        v-else
        text="Nachname absteigend"
        @click="sortAllSchueler('desc','schueler_name','schueler_vorname')"
      />
    </template>
    <template #dropdown_item_4>
      <sui-dropdown-item
        v-if="canSeeUsers"
        text="Vorname (SchülerIn) aufsteigend"
        @click="sortAllSchueler('asc','schueler_vorname','schueler_name')"
      />
      <sui-dropdown-item
        v-else
        text="Vorname aufsteigend"
        @click="sortAllSchueler('asc','schueler_vorname','schueler_name')"
      />
    </template>
    <template #dropdown_item_5>
      <sui-dropdown-item
        v-if="canSeeUsers"
        text="Vorname (SchülerIn) absteigend"
        @click="sortAllSchueler('desc','schueler_vorname','schueler_name')"
      />
      <sui-dropdown-item
        v-else
        text="Vorname absteigend"
        @click="sortAllSchueler('desc','schueler_vorname','schueler_name')"
      />
    </template>
    <template
      v-if="schoolType === 'SFZ'"
      #dropdown_item_6
    >
      <sui-dropdown-item
        text="Schule aufsteigend"
        @click="sortAllSchueler('asc','schule', '')"
      />
    </template>
    <template
      v-if="schoolType === 'SFZ'"
      #dropdown_item_7
    >
      <sui-dropdown-item
        text="Schule absteigend"
        @click="sortAllSchueler('desc','schule', '')"
      />
    </template>
    <template
      v-if="schoolType === 'SFZ'"
      #dropdown_item_8
    >
      <sui-dropdown-item
        text="Betreuungsart aufsteigend"
        @click="sortAllSchueler('asc','betreuungsart', '')"
      />
    </template>
    <template
      v-else
      #dropdown_item_8
    >
      <sui-dropdown-item
        text="Primärer Förderschwerpunkt aufsteigend"
        @click="sortAllSchueler('asc','primaerer_foerderschwerpunkt', '')"
      />
    </template>
    <template
      v-if="schoolType === 'SFZ'"
      #dropdown_item_9
    >
      <sui-dropdown-item
        text="Betreuungsart absteigend"
        @click="sortAllSchueler('desc','betreuungsart', '')"
      />
    </template>
    <template
      v-else
      #dropdown_item_9
    >
      <sui-dropdown-item
        text="Primärer Förderschwerpunkt absteigend"
        @click="sortAllSchueler('desc','primaerer_foerderschwerpunkt', '')"
      />
    </template>
    <template
      v-if="schoolType === 'SFZ' && is_admin"
      #dropdown_item_10
    >
      <sui-dropdown-item
        :text="getDropdownSortByUserText('aufsteigend')"
        @click="sortAllSchueler('asc','name', '',true)"
      />
    </template>
    <template
      v-if="schoolType === 'SFZ' && is_admin"
      #dropdown_item_11
    >
      <sui-dropdown-item
        :text="getDropdownSortByUserText('absteigend')"
        @click="sortAllSchueler('desc','name', '', true)"
      />
    </template>
    <template
      v-if="schoolType === 'SFZ'"
      #element_in_the_middle
    >
      <i
        id="school_filter_icon"
        class="caret down icon"
        @click="clickSchoolFilterDropdown(); changeSchoolFilterDropdownStyle()"
      />
      <sui-dropdown
        id="school_filter_dropdown"
        v-model="schoolFilter"
        :class="dropdownClasses"
        icon=""
        button
        pointing="top left"
        placeholder="Filtern"
        :options="allSchoolNames"
        @click="changeSchoolFilterDropdownStyle()"
      />
    </template>
    <template #search_icon_button>
      <button
        class="ui tertiary icon button search_icon_button padding-left-02em"
        @click="searchBarIsHidden = !searchBarIsHidden; sortIconIsHidden = true"
      >
        <i
          class="search icon"
          title="Nach SchülerIn suchen"
        />
      </button>
    </template>
    <template #create_new_list_item_button>
      <button
        v-if="canCreateStudents"
        :class="childIconButtonClasses"
        @click="$router.push({name: 'CreateSchueler'})"
      >
        <i
          class="child icon"
          title="SchülerIn anlegen"
        />
      </button>
      <button
        :class="questionIconButtonClasses"
        @click="toggleHelpTextModal()"
      >
        <i
          class="question icon"
          title="Hilfetext"
        />
      </button>
      <button
        v-if="canSeeUsers"
        :class="archiveIconButtonClasses"
        @click="filterEntriesByStatus()"
      >
        <i
          class="archive icon"
          title="Zwischen der Anzeige der aktiven und der abgeschlossenen Betreuungsfälle wechseln"
        />
      </button>
      <button
        v-if="gyCanAssociateUsersToStudents"
        class="ui tertiary icon button search_icon_button padding-left-right-02em"
      >
        <i
          class="angle double up icon cursor"
          title="Die zugeordneten User bei allen SchülerInnen einklappen"
          @click="collapseAssociatedUsers()"
        />
      </button>
      <button
        v-if="gyCanAssociateUsersToStudents"
        id="angle_double_down_button"
        class="ui tertiary icon button create_new_list_item_button padding-right-05em"
      >
        <i
          class="angle double down icon cursor"
          title="Die zugeordneten User bei allen SchülerInnen aufklappen"
          @click="expandAssociatedUsers()"
        />
      </button>
    </template>
  </BaseSortDropdown>
  <transition
    name="fade"
    :css="schuelerSuccessfullyCreated"
  >
    <BaseSuccessMessage
      v-if="schuelerSuccessfullyCreated"
      @successMessageCloseIconClicked="closeSchuelerCreatedSuccessMessage()"
    >
      <template #messageText>
        <div class="header">
          Das Erstellen war erfolgreich
        </div>
        <p v-if="betreuungsfallAbgeschlossenValueWhenSavingStudent === 1">
          Der Betreuungsfall der Sch&uuml;lerin / des Sch&uuml;lers wurde dabei auf abgeschlossen gesetzt.<br>
          <span v-if="schoolType === 'SFZ'">Als Schulleitung / AdministratorIn </span>
          <span v-else>Wenn Sie die Rolle "{{ userRoleThatCanSeeAbgeschlosseneBetreuungsfaelleGY }}" haben, </span>
          k&ouml;nnen Sie die abgeschlossenen Betreuungsf&auml;lle &uuml;ber das
          <i
            class="archive icon icons_color margin_right_0"
            title="Zwischen der Anzeige der aktiven und der abgeschlossenen Betreuungsfälle wechseln"
          />
          Icon rechts oben aufrufen.
        </p>
        <p v-else>Sie finden die Sch&uuml;lerin / den Sch&uuml;ler unten in der Liste.</p>
      </template>
    </BaseSuccessMessage>
  </transition>
  <transition
    name="fade"
    :css="schuelerSuccessfullyEdited"
  >
    <BaseSuccessMessage
      v-if="schuelerSuccessfullyEdited"
      @successMessageCloseIconClicked="closeSchuelerEditedSuccessMessage()"
    >
      <template #messageText>
        <div class="header">
          Die Bearbeitung war erfolgreich
        </div>
        <p v-if="old_betreuungsfall_abgeschlossen === 0 && betreuungsfallAbgeschlossenValueWhenSavingStudent === 1">
          Der Betreuungsfall der Sch&uuml;lerin / des Sch&uuml;lers wurde dabei auf abgeschlossen gesetzt.<br>
          <span v-if="schoolType === 'SFZ'">Als Schulleitung / AdministratorIn </span>
          <span v-else>Wenn Sie die Rolle "{{ userRoleThatCanSeeAbgeschlosseneBetreuungsfaelleGY }}" haben, </span>
          k&ouml;nnen Sie die abgeschlossenen Betreuungsf&auml;lle &uuml;ber das
          <i
            class="archive icon icons_color margin_right_0"
            title="Zwischen der Anzeige der aktiven und der abgeschlossenen Betreuungsfälle wechseln"
          />
          Icon rechts oben aufrufen.
        </p>
        <p v-else>Sie finden die bearbeitete Sch&uuml;lerin / den bearbeiteten Sch&uuml;ler unten in der Liste.</p>
      </template>
    </BaseSuccessMessage>
  </transition>
  <transition
    name="fade"
    :css="usersSuccessfullyAssignedToTheStudentGy"
  >
    <BaseSuccessMessage
      v-if="usersSuccessfullyAssignedToTheStudentGy"
      @successMessageCloseIconClicked="closeUsersAssignedSuccessMessage()"
    >
      <template #messageText>
        <div class="header">
          Das Speichern der Änderungen war erfolgreich
        </div>
        <p>Sie finden die Änderungen an der Zuordnung unten in der Liste.</p>
      </template>
    </BaseSuccessMessage>
  </transition>
  <transition
    name="fade"
    :css="adminRightsOfOwnUserHaveBeenRemoved"
  >
    <BaseSuccessMessage
      v-if="adminRightsOfOwnUserHaveBeenRemoved"
      @successMessageCloseIconClicked="closeAdminRightsOfOwnUserHaveBeenRemovedMessage()"
    >
      <template #messageText>
        <div class="header">
          Die Bearbeitung war erfolgreich
        </div>
        <p>Sie haben die Administrationsrechte f&uuml;r Ihren User entfernt.<br>
        Sie wurden zum Home-Bereich weitergeleitet.</p>
      </template>
    </BaseSuccessMessage>
  </transition>
  <BaseInfoMessage
    v-if="!stateSorted || !stateSorted.length"
  >
    <template
      v-if="canSeeUsers && show_active_cases === true"
      #messageText
    >
      <div class="header">
        In Ihrem MSD-Portal wurden noch keine Sch&uuml;lerInnen erstellt
      </div>
      <p>
        &Uuml;ber einen Klick auf das
        <i
          class="child icon icons_color margin_right_0"
          title="SchülerIn anlegen"
        />
        Icon rechts oben k&ouml;nnen Sie
        <span v-if="schoolType === 'SFZ'">und MSD-MitarbeiterInnen</span> Sch&uuml;lerInnen anlegen.<span v-if="schoolType === 'SFZ'"> Eine Sch&uuml;lerIn, die/der neu angelegt wurde, ist dem User zugeordnet, der die Sch&uuml;lerin/den Sch&uuml;ler angelegt hat.</span>
      </p>
      <p v-if="schoolType === 'SFZ'">
        Im Administrationsbereich, den Sie über das Men&uuml; unten aufrufen k&ouml;nnen, k&ouml;nnen Sie die Zuordnung von Sch&uuml;lerInnen zu Usern ver&auml;ndern.
      </p>
      <p v-else>
        Wenn Sie eine Sch&uuml;lerin/einen Sch&uuml;ler angelegt haben, k&ouml;nnen Sie ihr/ihm im Home-Bereich &uuml;ber einen Klick auf das
        <i
          class="hands helping icon icons_color margin_right_0"
          title="SchülerIn User zuordnen"
        />
        Icon rechts bei der Sch&uuml;lerin/dem Sch&uuml;ler hauptverantwortliche Lehrkr&auml;fte, MSD-MitarbeiterInnen und TherapeutInnen zuordnen.
      </p>
    </template>
    <template
      v-else-if="canSeeUsers && show_active_cases === false"
      #messageText
    >
      <div class="header">
        In Ihrem MSD-Portal gibt es noch keine abgeschlossenen Betreuungsf&auml;lle
      </div>
      <p v-if="schoolType === 'SFZ'">
        Bei Sch&uuml;lerInnen kann jeder User im Betreuungsakt unter Stammdaten den Betreuungsfall abschließen,
        indem sie / er bei "Betreuungsfall abgeschlossen" Ja ausw&auml;hlt und speichert.
      </p>
      <p v-else>
        Jeder User, der eine Sch&uuml;lerin / einen Sch&uuml;ler bearbeiten darf,
        kann im Betreuungsakt dieser Sch&uuml;lerin / dieses Sch&uuml;lers den Betreuungsfall abschließen,
        indem er bei "Betreuungsfall abgeschlossen" Ja angibt und speichert.
      </p>
    </template>
    <template
      v-else-if="schoolType === 'GY' && (userRole === 'Fachlehrkraft' || userRole === 'SozialpädagogIn' || isHauptverantwortlicheLehrkraft)"
      #messageText
    >
      <div class="header">
        In Ihrem MSD-Portal wurden noch keine Sch&uuml;lerInnen erstellt
      </div>
      <p>
        User mit der Rolle "SchulpsychologIn" k&ouml;nnen in Ihrem MSD-Portal Sch&uuml;lerInnen anlegen.
      </p>
    </template>
    <template
      v-else-if="schoolType === 'SFZ'"
      #messageText
    >
      <div class="header">
        Ihnen sind noch keine Sch&uuml;lerInnen zugeordnet
      </div>
      <p>
        &Uuml;ber einen Klick auf das
        <i
          class="child icon icons_color margin_right_0"
          title="SchülerIn anlegen"
        />
        Icon rechts oben k&ouml;nnen Sie eine neue Sch&uuml;lerin / einen neuen Sch&uuml;ler anlegen. Diese Sch&uuml;lerin / dieser Sch&uuml;ler ist Ihnen dann zugeordnet.
      </p>
      <p>
        Wenn &Auml;nderungen an der Zuordnung von Sch&uuml;lerInnen zu Usern vorgenommen werden sollen, wenden Sie sich bitte an die Schulleitung / Administration. Sie kann diese &Auml;nderungen vornehmen.
      </p>
    </template>
    <template
      v-else-if="schoolType === 'GY' && (userRole === 'MSD-MitarbeiterIn' || userRole === 'TherapeutIn')"
      #messageText
    >
      <div class="header">
        Ihnen sind noch keine Sch&uuml;lerInnen zugeordnet
      </div>
      <p>
        User mit der Rolle "SchulpsychologIn" k&ouml;nnen Ihnen Sch&uuml;lerInnen zuordnen.
      </p>
    </template>
  </BaseInfoMessage>
  <BaseList
    :list-items-array="schuelerSearchResult"
    :number-of-content-properties="numberOfContentPropertiesList"
  >
    <template #header="{currentListItem}">
      {{ currentListItem.schueler_name }}, {{ currentListItem.schueler_vorname }}<br>
    </template>
    <template
      v-if="schoolType === 'SFZ'"
      #content_1="{currentListItem}"
    >
      <span class="smart_word_break">
        {{ currentListItem.schule }}<br>
      </span>
    </template>
    <template #content_2="{currentListItem}">
      Geburtsdatum: {{ formatDate(currentListItem.geburtsdatum) }}<br>
    </template>
    <template
      v-if="schoolType === 'SFZ'"
      #content_3="{currentListItem}"
    >
      Betreuungsart: {{ currentListItem.betreuungsart }}<br>
    </template>
    <template
      v-else
      #content_3="{currentListItem}"
    >
      Prim&auml;rer F&ouml;rderschwerpunkt: {{ currentListItem.primaerer_foerderschwerpunkt }}<br>
    </template>
    <template
      v-if="show_active_cases === false"
      #content_4="{currentListItem}">
      <span v-if="currentListItem.betreuungsfall_abgeschlossen_date">
        Betreuungsfall abgeschlossen am: {{ formatDate(currentListItem.betreuungsfall_abgeschlossen_date) }}
      </span>
      <span v-else>
        Kein Abschlussdatum des Betreuungsfalls gespeichert
      </span>
      <br>
    </template>
    <template #content_5="{currentListItem}">
      <div v-if="show_active_cases === false">
        <div v-if="Array.isArray(currentListItem.user_histories) && currentListItem.user_histories.length">
          <span v-if="schoolType === 'SFZ'">
            Vor Abschluss des Betreuungsfalls betreut von: {{ currentListItem.user_histories[0].user_name }}
          </span>
          <div
            v-else-if="schoolType === 'GY'"
            @click="expandOrCollapseAssociatedUsersOfOneStudent(currentListItem.id)"
          >
            <i
              v-if="currentListItem.expand_associated_users"
              class="caret down icon betreut_von_icon cursor"
            />
            <i
              v-else
              class="caret right icon betreut_von_icon cursor"
            />
            Zugeordnete User vor Abschluss des Betreuungsfalls:
            <div v-if="resetUserRolesThatAlreadyOccurred()" />
            <div v-if="currentListItem.expand_associated_users">
              <span
                v-for="(userHistoryEntry, userHistoryIndex) in currentListItem.user_histories"
                :key="userHistoryIndex"
              >
                <span v-if="isFirstOccurrenceOfUserRole(userHistoryEntry.user_role)">{{ formatUserRoleHeader(userHistoryEntry.user_role) }}:<br></span>
                &bull; {{ userHistoryEntry.user_name }}<br>
              </span>
            </div>
          </div>
        </div>
        <div v-else>
          Keine Informationen zu
          <span v-if="schoolType === 'SFZ'">zugeordnetem User</span>
          <span v-else>zugeordneten Usern</span>
          vor Abschluss des Betreuungsfalls verfügbar
        </div>
      </div>
      <div v-else-if="canSeeUsers">
        <span v-if="currentListItem.user_sfz && currentListItem.user_sfz.name">
          Betreut von: {{ currentListItem.user_sfz.name }}
        </span>
        <div
          v-else-if="Array.isArray(currentListItem.users_gy) && currentListItem.users_gy.length"
          @click="expandOrCollapseAssociatedUsersOfOneStudent(currentListItem.id)"
        >
          <i
            v-if="currentListItem.expand_associated_users"
            class="caret down icon betreut_von_icon cursor"
          />
          <i
            v-else
            class="caret right icon betreut_von_icon cursor"
          />
          Zugeordnete User:
          <div v-if="resetUserRolesThatAlreadyOccurred()" />
          <div v-if="currentListItem.expand_associated_users">
            <span
              v-for="(oneUser, userIndex) in currentListItem.users_gy"
              :key="userIndex"
            >
              <span v-if="isFirstOccurrenceOfUserRole(oneUser.role)">{{ formatUserRoleHeader(oneUser.role) }}:<br></span>
              &bull; {{ oneUser.name }}<br>
            </span>
          </div>
        </div>
        <div v-else>
          Der Sch&uuml;lerin / dem Sch&uuml;ler
          <span v-if="schoolType === 'SFZ'">ist kein User</span>
          <span v-else>sind keine User</span> zugeordnet
        </div>
      </div>
      <div v-else-if="isHauptverantwortlicheLehrkraft">
        <span v-if="Array.isArray(currentListItem.users_gy) && currentListItem.users_gy.length">
          <b>hauptverantwortlich</b>
        </span>
      </div>
    </template>
    <template
      v-if="canExportBetreuungsakt && gyCanAssociateUsersToStudents && show_active_cases"
      #icons="{currentListItem}"
    >
      <i
        class="hands helping icon cursor"
        title="SchülerIn User zuordnen"
        @click="$router.push({
          name: 'SchuelerUsersAssignGY',
          params: { id: currentListItem.id }
        })"
      />
      <i
        class="address card icon cursor tiny-margin-left"
        title="Betreuungsakt"
        @click="$router.push({
          name: 'Betreuungsakt',
          params: { id: currentListItem.id }
        })"
      />
      <br>
      <i
        class="times icon cursor margin_top"
        title="L&ouml;schen"
        @click="deleteSchueler(currentListItem.id)"
      />
      <i
        class="file export icon cursor standard_margin_left margin_top"
        title="Betreuungsakt exportieren"
        @click="exportBetreuungsakt($event, currentListItem.id, currentListItem.schueler_name, currentListItem.schueler_vorname)"
      />
    </template>
    <template
      v-else
      #icons="{currentListItem}"
    >
      <i
        v-if="gyCanAssociateUsersToStudents && show_active_cases"
        class="hands helping icon cursor"
        title="SchülerIn User zuordnen"
        @click="$router.push({
          name: 'SchuelerUsersAssignGY',
          params: { id: currentListItem.id }
        })"
      />
      <i
        :class="addressCardClass"
        title="Betreuungsakt"
        @click="$router.push({
          name: 'Betreuungsakt',
          params: { id: currentListItem.id }
        })"
      />
      <i
        v-if="checkCanModifyStudentData(currentListItem)"
        class="times icon small_margin_left cursor"
        title="L&ouml;schen"
        @click="deleteSchueler(currentListItem.id)"
      />
      <i
        v-if="canExportBetreuungsakt"
        class="file export icon small_margin_left margin_right cursor"
        title="Betreuungsakt exportieren"
        @click="exportBetreuungsakt($event, currentListItem.id, currentListItem.schueler_name, currentListItem.schueler_vorname)"
      />
    </template>
  </BaseList>
  <BaseConfirmModal v-model="modalDeleteSchueler">
    <template #modalText>
      Möchten Sie die Schülerin / den Schüler {{ schueler_to_delete_name }} wirklich löschen?
    </template>
    <template #confirmButton>
      <sui-button
        primary
        @click="deleteSchuelerYes()"
      >
        Ja
      </sui-button>
    </template>
    <template #cancelButton>
      <sui-button @click="deleteSchuelerNo()">
        Nein
      </sui-button>
    </template>
  </BaseConfirmModal>
  <BaseConfirmModal
    v-model="modalBetreuungsaktExportStarted"
    header="Export des Betreuungsaktes"
  >
    <template #modalText>
      <span>
        Die Betreuungsakt-Exportdatei wird gerade für den Download vorbereitet.<br>
        Je nachdem, wie viele Eintr&auml;ge im Betreuungsakt der Sch&uuml;lerin / des Sch&uuml;lers vorhanden sind, kann es einen Moment dauern bis der Download der Exportdatei gestartet wird.<br>
        Bitte haben Sie ein wenig Geduld.
      </span>
    </template>
    <template #cancelButton>
      <sui-button @click="betreuungsaktExportStartedCancel()">
        Meldung ausblenden
      </sui-button>
    </template>
  </BaseConfirmModal>
  <BaseConfirmModal
    v-model="modalInfoAboutDocumentation"
    header="Informationen zum Start im MSD-Portal"
  >
    <template #modalText>
      <p>Sie finden &uuml;ber das Men&uuml; unten unter Informationen -> Hilfe ein Hilfe-Dokument mit Informationen zu den Funktionen des MSD-Portals.</p>
      <p>Daneben befindet sich auf mehreren Seiten des MSD-Portals rechts oben ein Fragezeichen-Icon, &uuml;ber das eine Dokumentation der entsprechenden Seite aufgerufen werden kann.</p>
      <p>Wir w&uuml;nschen Ihnen einen guten Start im MSD-Portal!</p>
    </template>
    <template #cancelButton>
      <sui-button @click="closeInfoAboutDocumentationModal()">
        Meldung schließen
      </sui-button>
    </template>
  </BaseConfirmModal>
  <BaseConfirmModal
    v-model="requestReturnedForbiddenResponseStatus"
    header="Fehlende Berechtigung"
  >
    <template #modalText>
      Sie haben gerade versucht eine Aktion durchzuf&uuml;hren, für die Sie nicht die n&ouml;tigen Rechte besitzen.<br>
      M&ouml;glicherweise wurde von einem User mit Administrationszugang Ihre Userrolle ge&auml;ndert und damit haben sich Ihre Rechte ver&auml;ndert.<br>
      Bei Fragen wenden Sie sich bitte an die AdministratorInnen des MSD-Portals Ihrer Schule.<br>
      Sie wurden zum Home-Bereich weitergeleitet.
    </template>
    <template #cancelButton>
      <sui-button @click="closeRequestReturnedForbiddenResponseStatusMessage()">
        schließen
      </sui-button>
    </template>
  </BaseConfirmModal>
  <sui-modal v-model="modalHelpText">
    <sui-modal-header>Dokumentation: Home</sui-modal-header>
    <sui-modal-content class="add-scrollbar-on-overflow">
      <sui-modal-description>
        <sui-table unstackable celled>
          <sui-table-header>
            <sui-table-row>
              <sui-table-headerCell colspan="2">Beschreibung der Icons</sui-table-headerCell>
            </sui-table-row>
            <sui-table-row>
              <sui-table-header-cell>Icon</sui-table-header-cell>
              <sui-table-header-cell>Beschreibung</sui-table-header-cell>
            </sui-table-row>
          </sui-table-header>
          <sui-table-body>
            <sui-table-row v-if="gyCanAssociateUsersToStudents">
              <sui-table-cell class="table_cell_align">
                <i
                  class="angle double up icon no-margin-right"
                  title="Die zugeordneten User bei allen SchülerInnen einklappen"
                />
              </sui-table-cell>
              <sui-table-cell>
                Die zugeordneten User bei allen Sch&uuml;lerInnen einklappen
              </sui-table-cell>
            </sui-table-row>
            <sui-table-row v-if="gyCanAssociateUsersToStudents">
              <sui-table-cell class="table_cell_align">
                <i
                  class="angle double down icon no-margin-right"
                  title="Die zugeordneten User bei allen SchülerInnen aufklappen"
                />
              </sui-table-cell>
              <sui-table-cell>
                Die zugeordneten User bei allen Sch&uuml;lerInnen aufklappen
              </sui-table-cell>
            </sui-table-row>
            <sui-table-row v-if="canSeeUsers">
              <sui-table-cell class="table_cell_align">
                <i
                  class="archive icon no-margin-right"
                  title="Zwischen der Anzeige der aktiven und der abgeschlossenen Betreuungsfälle wechseln"
                />
              </sui-table-cell>
              <sui-table-cell>
                Zwischen der Anzeige der aktiven und der abgeschlossenen Betreuungsf&auml;lle wechseln
              </sui-table-cell>
            </sui-table-row>
            <sui-table-row v-if="canCreateStudents">
              <sui-table-cell class="table_cell_align">
                <i
                  class="child icon no-margin-right"
                  title="SchülerIn anlegen"
                />
              </sui-table-cell>
              <sui-table-cell>
                Eine Sch&uuml;lerin / einen Sch&uuml;ler anlegen
              </sui-table-cell>
            </sui-table-row>
            <sui-table-row>
              <sui-table-cell class="table_cell_align">
                <i
                  class="search icon no-margin-right"
                  title="Nach SchülerIn suchen"
                />
              </sui-table-cell>
              <sui-table-cell>
                Nach einer Sch&uuml;lerin / einem Sch&uuml;ler anhand des Vornamens und / oder Nachnamens suchen
              </sui-table-cell>
            </sui-table-row>
            <sui-table-row v-if="gyCanAssociateUsersToStudents">
              <sui-table-cell class="table_cell_align">
                <i
                  class="hands helping icon no-margin-right"
                  title="SchülerIn User zuordnen"
                />
              </sui-table-cell>
              <sui-table-cell>
                Leitet Sie zu einer Funktion, in der Sie der Sch&uuml;lerin / dem Sch&uuml;ler eine Hauptverantwortliche Lehrkraft, MSD-MitarbeiterInnen und TherapeutInnen zuordnen k&ouml;nnen
              </sui-table-cell>
            </sui-table-row>
            <sui-table-row>
              <sui-table-cell class="table_cell_align">
                <i
                  class="address card icon no-margin-right"
                  title="Betreuungsakt"
                />
              </sui-table-cell>
              <sui-table-cell>
                Zum Betreuungsakt der Sch&uuml;lerin / des Sch&uuml;lers navigieren
              </sui-table-cell>
            </sui-table-row>
            <sui-table-row>
              <sui-table-cell class="table_cell_align">
                <i
                  class="times icon no-margin-right"
                  title="L&ouml;schen"
                />
              </sui-table-cell>
              <sui-table-cell>
                Die Sch&uuml;lerin / den Sch&uuml;ler l&ouml;schen
              </sui-table-cell>
            </sui-table-row>
            <sui-table-row v-if="canExportBetreuungsakt">
              <sui-table-cell class="table_cell_align">
                <i
                  class="file export icon no-margin-right"
                  title="Betreuungsakt exportieren"
                />
              </sui-table-cell>
              <sui-table-cell>
                Den Betreuungsakt der Sch&uuml;lerin / des Sch&uuml;lers exportieren: Es wird eine ZIP-Datei mit allen Eintr&auml;gen und Dateien der Sch&uuml;lerin / des Sch&uuml;lers erstellt, die Sie abspeichern k&ouml;nnen
              </sui-table-cell>
            </sui-table-row>
          </sui-table-body>
        </sui-table>
        <p
          v-if="schoolType === 'GY' && (userRole === 'MSD-MitarbeiterIn' || userRole === 'TherapeutIn')"
          class="modal-margin-top"
        >
          <b>Liste der Sch&uuml;lerInnen: </b><br>
          In der Liste der Sch&uuml;lerInnen
          sehen Sie alle Sch&uuml;lerInnen, die Ihnen zugeordnet sind. User mit
          der Rolle "SchulpsychologIn" k&ouml;nnen Ihnen Sch&uuml;lerInnen
          zuordnen.<br>
          In der Liste der Sch&uuml;lerInnen werden Ihnen
          aktive Betreuungsf&auml;lle angezeigt. Abgeschlossene
          Betreuungsf&auml;lle k&ouml;nnen von Usern mit der Rolle
          "SchulpsychologIn" wiederer&ouml;ffnet werden.
        </p>
        <p
          v-else-if="isHauptverantwortlicheLehrkraft || !checkCanModifyStudentData()"
          class="modal-margin-top"
        >
          <b>Liste der Sch&uuml;lerInnen: </b><br>
          In der Liste der Sch&uuml;lerInnen
          sehen Sie alle aktiven Betreuungsf&auml;lle an Ihrer Schule.
          <span v-if="isHauptverantwortlicheLehrkraft">
            Bei SchülerInnen, denen Sie als hauptverantwortliche Lehrkraft zugeordnet sind,
            steht in der Liste der SchülerInnen jeweils bei den entsprechenden SchülerInnen
            in der letzten Zeile die Angabe "hauptverantwortlich".<br>
          </span>
          Abgeschlossene Betreuungsf&auml;lle k&ouml;nnen von Usern mit der
          Rolle "SchulpsychologIn" wiederer&ouml;ffnet werden.
        </p>
        <p
          v-else-if="schoolType === 'SFZ' && is_admin === false"
          class="modal-margin-top"
        >
          <b>Liste der Sch&uuml;lerInnen: </b><br>
          In der Liste der Sch&uuml;lerInnen
          sehen Sie alle Sch&uuml;lerInnen, die Ihnen zugeordnet sind. Wenn Sie
          eine neue Sch&uuml;lerin / einen neuen Sch&uuml;ler anlegen, ist
          diese Sch&uuml;lerin / dieser Sch&uuml;ler Ihnen zugeordnet.
          Wenn &Auml;nderungen an der Zuordnung von Sch&uuml;lerInnen zu Usern
          vorgenommen werden sollen, wenden Sie sich bitte an die
          Schulleitung / Administration. Sie kann diese &Auml;nderungen vornehmen.
        </p>
        <p :class="helpAreaTextClasses">
          Weitere Informationen zu den Funktionen, die im Home-Bereich aufgerufen
          werden k&ouml;nnen, finden Sie im Hilfe-Bereich, der über den
          Men&uuml;punkt "Informationen" im Navigationsmen&uuml; unten
          zug&auml;nglich ist.
        </p>
      </sui-modal-description>
    </sui-modal-content>
    <sui-modal-actions>
      <sui-button
        type="button"
        @click="toggleHelpTextModal"
      >
        schließen
      </sui-button>
    </sui-modal-actions>
  </sui-modal>
  <sui-modal v-model="pupilHasNoEntriesModalOpen">
    <sui-modal-header>Keine Eintr&auml;ge vorhanden</sui-modal-header>
    <sui-modal-content>
      <sui-modal-description>
        <p>
          Im Betreuungsakt der Sch&uuml;lerin / des Sch&uuml;lers wurden noch keine Eintr&auml;ge erstellt.<br>
          Der Betreuungsakt der Sch&uuml;lerin / des Sch&uuml;lers kann nur exportiert werden, wenn Eintr&auml;ge im Betreuungsakt erstellt wurden.
        </p>
      </sui-modal-description>
    </sui-modal-content>
    <sui-modal-actions>
      <sui-button
        type="button"
        @click="togglePupilHasNoEntriesModal"
      >
        Zur&uuml;ck
      </sui-button>
    </sui-modal-actions>
  </sui-modal>
  <sui-modal v-model="duplicateFileNamesModalOpen">
    <sui-modal-header>Dateinamen doppelt vorhanden</sui-modal-header>
    <sui-modal-content class="add-scrollbar-on-overflow">
      <sui-modal-description>
        <p>
          Im Betreuungsakt der Sch&uuml;lerin / des Sch&uuml;lers sind Dateinamen doppelt vorhanden.
          Bitte benennen Sie Dateien um, bevor Sie den Betreuungsakt exportieren. Dadurch wird verhindert, dass doppelte Dateinamen in der Exportdatei des Betreuungsaktes &uuml;berschrieben werden.<br>
          In folgenden Bereichen des Betreuungsaktes sind Dateinamen mehrfach vorhanden:
        </p>
        <ul>
          <li
            v-for="(category, index) in categories_with_duplicate_file_names"
            :key="index"
          >
            {{ getBetreuungsaktFunctionFullName(category) }}
          </li>
        </ul>
        <p>
          <span
            v-if="schoolType === 'GY'
              && (userRole === 'Fachlehrkraft' || userRole === 'SozialpädagogIn' || userRole === 'TherapeutIn' || userRole === 'MSD-MitarbeiterIn'
                || userRole === 'Hauptverantwortliche Lehrkraft' && !isAuthenticatedHauptverantwortlicheLkUserAssignedToExportBetreuungsaktStudent)"
          >
            <span v-if="userRole === 'Hauptverantwortliche Lehrkraft' && !isAuthenticatedHauptverantwortlicheLkUserAssignedToExportBetreuungsaktStudent">
              Als hauptverantwortliche Lehrkraft, die der Schülerin / dem Schüler nicht zugeordnet ist,
              k&ouml;nnen Sie sich
            </span>
            <span v-else>
              Als User mit der Rolle {{ userRole }} k&ouml;nnen Sie sich
            </span>
            <span v-if="userRole === 'TherapeutIn'">
              im Bereich "Therapie"
            </span>
            <span v-else-if="userRole === 'MSD-MitarbeiterIn'">
              außer im Bereich "Therapie" in allen Bereichen
            </span>
            <span v-else>
              in den Bereichen "Beobachtungen" und "F&ouml;rderplan und informelle Diagnostik"
            </span>
            des Betreuungsaktes mit einem Klick auf das
            <i
              class="clone icon icons_color margin_right_0"
              title="Einträge mit doppelten Dateinamen farblich hervorheben"
            />
            Icon rechts oben Eintr&auml;ge mit doppelten Dateinamen anzeigen
            lassen und k&ouml;nnen die Liste der Eintr&auml;ge auch nach diesen
            Eintr&auml;gen sortieren.<br>
            In der Liste der Eintr&auml;ge k&ouml;nnen Sie mit Klick auf das
            <i
              class="edit icon margin_right_0"
              title="Eintrag bearbeiten"
            />
            Icon Eintr&auml;ge bearbeiten und hochgeladene Dateien umbenennen.<br>
            <span v-if="userRole === 'MSD-MitarbeiterIn'">
              Falls doppelte Dateinamen im Bereich "Therapie" umbenannt werden sollen,
              können Sie sich an eine hauptverantwortliche Lehrkraft,
              die der Sch&uuml;lerin / dem Sch&uuml;ler zugeordnet ist, oder an User mit den Rollen
              "TherapeutIn" oder "SchulpsychologIn" wenden.
              Diese User können im Bereich "Therapie" Dateien umbenennen.
            </span>
            <span v-else>
              Falls doppelte Dateinamen in anderen Bereichen des Betreuungsaktes
              umbenannt werden sollen, können Sie sich an einen User mit der Rolle "hauptverantwortliche Lehrkraft",
              der der Sch&uuml;lerin / dem Sch&uuml;ler zugeordnet ist, oder an einen User mit der Rolle
              "SchulpsychologIn" wenden. Diese User können in allen Bereichen des Betreuungsaktes Dateien umbenennen.
            </span>
          </span>
          <span v-else>
            Sie k&ouml;nnen sich in diesen Bereichen mit einem Klick auf das
            <i
              class="clone icon icons_color margin_right_0"
              title="Einträge mit doppelten Dateinamen farblich hervorheben"
            />
            Icon rechts oben Eintr&auml;ge mit doppelten Dateinamen anzeigen lassen und k&ouml;nnen die Liste der Eintr&auml;ge auch nach diesen Eintr&auml;gen sortieren.<br>
            In der Liste der Eintr&auml;ge k&ouml;nnen Sie mit Klick auf das
            <i
              class="edit icon margin_right_0"
              title="Eintrag bearbeiten"
            />
            Icon Eintr&auml;ge bearbeiten und hochgeladene Dateien umbenennen.
          </span>
        </p>
      </sui-modal-description>
    </sui-modal-content>
    <sui-modal-actions>
      <sui-button
        type="button"
        @click="toggleDuplicateFileNamesModal"
      >
        Zur&uuml;ck
      </sui-button>
    </sui-modal-actions>
  </sui-modal>
</template>

<script>
import store from "@/store";
import BaseSearchBar from "../components/BaseSearchBar";
import BaseSortDropdown from "../components/BaseSortDropdown";
import BaseInfoMessage from "../components/BaseInfoMessage";
import BaseList from "../components/BaseList";
import BaseConfirmModal from "../components/BaseConfirmModal";
import BaseSuccessMessage from "../components/BaseSuccessMessage";
import {checkCanModifyStudentData} from '@/authHelperFunctions';
import {convertIsoDateToGermanDate} from '@/helperFunctions';
import {getBetreuungsaktFunctionFullName} from '@/helperFunctions';
import {isAuthenticatedHauptverantwortlicheLkUserIsAssignedToCurrentStudent} from "../authHelperFunctions";

// This variable is defined outside data to
// prevent the v-for loop from being changed, when this variable changes,
// which would then change this variable again and so on (FLOG)
let userRolesThatAlreadyOccurred = new Set();

export default {
  name: 'HomeView',
  components: {BaseList, BaseSearchBar, BaseSortDropdown, BaseInfoMessage, BaseConfirmModal, BaseSuccessMessage},
  data: function () {
    return {
      isAuthenticatedHauptverantwortlicheLkUserAssignedToExportBetreuungsaktStudent: false,
      searchQuery: "",
      modalDeleteSchueler: null,
      modalHelpText: false,
      duplicateFileNamesModalOpen: false,
      pupilHasNoEntriesModalOpen: false,
      modalBetreuungsaktExportStarted: false,
      searchBarIsHidden: true,
      sortIconIsHidden: false,
      schoolFilter: null
    }
  },
  computed: {
    adminRightsOfOwnUserHaveBeenRemoved() {
      return store.state.editUserModule.adminRightsOfOwnUserHaveBeenRemoved;
    },
    addressCardClass() {
      let addressCardCSSClass = "address card icon cursor";
      if (!this.canExportBetreuungsakt && this.gyCanAssociateUsersToStudents){
        return addressCardCSSClass + " big-margin-left";
      }
      else{
        return addressCardCSSClass;
      }
    },
    archiveIconButtonClasses() {
      let archiveIconButtonCSSClass = "ui tertiary icon button create_new_list_item_button";
      if (this.schoolType === 'SFZ'){
        return archiveIconButtonCSSClass + " padding-right-05em";
      }
      else{
        return archiveIconButtonCSSClass + " padding-left-right-06em";
      }
    },
    betreuungsfallAbgeschlossenValueWhenSavingStudent() {
      return store.state.schuelerModule.betreuungsfallAbgeschlossenValueWhenSavingStudent;
    },
    canCreateStudents() {
      return store.getters['auth/canCreateStudents'];
    },
    canExportBetreuungsakt() {
      return store.getters['auth/canExportBetreuungsakt'];
    },
    canSeeUsers() {
      return store.getters['auth/canSeeUsers'];
    },
    childIconButtonClasses() {
      let childIconButtonCSSClass = "ui tertiary icon button create_new_list_item_button";
      if (this.schoolType === 'SFZ'){
        return childIconButtonCSSClass + " padding-left-right-04em";
      }
      else{
        return childIconButtonCSSClass + " padding-left-right-05em";
      }
    },
    schoolsFilteredByBetreuungsfallStatus() {
      return store.getters['allSchuelerModule/schoolsFilteredByBetreuungsfallStatus'];
    },
    gyCanAssociateUsersToStudents() {
      if (this.schoolType === 'GY' && this.userRole === 'SchulpsychologIn') {
        return true;
      }
      else {
        return false;
      }
    },
    helpAreaTextClasses() {
      if (this.canSeeUsers === true){
        return "modal-margin-top";
      }
      else {
        return "";
      }
    },
    isHauptverantwortlicheLehrkraft() {
      return this.schoolType === 'GY' && this.userRole === 'Hauptverantwortliche Lehrkraft';
    },
    modalInfoAboutDocumentation() {
      return store.state.auth.modalInfoAboutDocumentation;
    },
    old_betreuungsfall_abgeschlossen() {
      return store.state.schuelerModule.old_betreuungsfall_abgeschlossen;
    },
    // Increases the padding, if a user can export the Betreuungsakt or modify students
    // so that the question icon is in line with the then existing additional icons below it
    questionIconButtonClasses() {
      let questionIconButtonCSSClass = "ui tertiary icon button create_new_list_item_button";
      if (this.schoolType === 'GY' && this.canExportBetreuungsakt === true
          && this.userRole !== 'SchulpsychologIn'){
        return questionIconButtonCSSClass + " padding-left-right-035em";
      }
      else if (this.schoolType === 'GY' && this.canExportBetreuungsakt === false
          && (this.userRole === 'Hauptverantwortliche Lehrkraft' || this.userRole === 'MSD-MitarbeiterIn')){
        return questionIconButtonCSSClass + " padding-left-right-05em";
      }
      else{
        return questionIconButtonCSSClass + " padding-left-right-01em";
      }
    },
    requestReturnedForbiddenResponseStatus() {
      return store.state.config.requestReturnedForbiddenResponseStatus;
    },
    schuelerSuccessfullyCreated() {
      if (store.state.schuelerModule.schuelerSuccessfullyCreated) {
        return store.state.schuelerModule.schuelerSuccessfullyCreated;
      }
      else {
        return false;
      }
    },
    schuelerSuccessfullyEdited() {
      if (store.state.schuelerModule.schuelerSuccessfullyEdited) {
        return store.state.schuelerModule.schuelerSuccessfullyEdited;
      }
      else {
        return false;
      }
    },
    stateSorted() {
      return store.getters['allSchuelerModule/stateSorted'](this.$options.name, 'schueler');
    },
    userId() {
      return store.state.auth.user.id;
    },
    userRole() {
      return store.state.auth.user.role;
    },
    userRoleThatCanSeeAbgeschlosseneBetreuungsfaelleGY() {
      return store.state.auth.userRoleThatCanSeeAbgeschlosseneBetreuungsfaelleGY;
    },
    schuelerSearchResult() {
      if (this.stateSorted){
        var allSchuelerFiltered;

        // Filter allSchueler by the school that the user has chosen as filter
        if (this.schoolFilter !== null && this.schoolFilter !== "Filter aufheben"){
          allSchuelerFiltered = this.stateSorted.filter(obj => {
            return obj.schule === this.schoolFilter
          })
        }
        else {
          allSchuelerFiltered = this.stateSorted;
        }

        // Filter allSchueler by the search term that the user entered
        return allSchuelerFiltered.filter((schueler) => {
          let schuelerNameSpaceConcat = schueler.schueler_name + " " + schueler.schueler_vorname;
          let schuelerNameCommaConcat = schueler.schueler_name + ", " + schueler.schueler_vorname;
          return (
              schuelerNameSpaceConcat
              .toLowerCase()
              .indexOf(this.searchQuery.toLowerCase()) !== -1 ||
              schuelerNameCommaConcat
              .toLowerCase()
              .indexOf(this.searchQuery.toLowerCase()) !== -1
          )
        })
      }
      else {
        return [];
      }
    },
    allSchoolNames() {
      if (this.schoolsFilteredByBetreuungsfallStatus){
        let allSchoolNamesArray = [];
        this.schoolsFilteredByBetreuungsfallStatus.forEach(function (e) {
          allSchoolNamesArray.push(e.school_name);
        })
        // Sort alphabetically
        allSchoolNamesArray.sort((a, b) => a.localeCompare(b, 'de', {sensitivity: 'base'}));
        // Add "Filter aufheben" at the first position of the array
        return ["Filter aufheben"].concat(allSchoolNamesArray);
      }
      else {
        return [];
      }
    },
    schoolType() {
      return store.state.auth.schoolType;
    },
    is_admin() {
      return store.state.auth.user.is_admin === 1;
    },
    numberOfContentPropertiesList() {
      if (this.canSeeUsers || this.isHauptverantwortlicheLehrkraft) {
        return 5
      }
      else {
        return 4
      }
    },
    numberOfContentPropertiesSortDropdown() {
      if (this.canSeeUsers) {
        return 11
      }
      else {
        return 9
      }
    },
    schueler_to_delete_name() {
      if (store.state.schuelerModule.schueler_to_delete_id) {
        let schueler_to_delete_id = store.state.schuelerModule.schueler_to_delete_id;
        let schueler_to_delete = this.schuelerSearchResult.find(obj => {return obj.id === schueler_to_delete_id});
        return schueler_to_delete ? schueler_to_delete.schueler_vorname + " " + schueler_to_delete.schueler_name : "";
      }
      else {
        return "";
      }
    },
    categories_with_duplicate_file_names() {
      if (store.state.filesModule.categoriesWithDuplicateFileNames){
        return store.state.filesModule.categoriesWithDuplicateFileNames;
      }
      else {
        return [];
      }
    },
    show_active_cases() {
      return store.state.allSchuelerModule.showActiveCases;
    },
    dropdownClasses() {
      if (this.gyCanAssociateUsersToStudents) {
        return "sort_dropdown school_filter_dropdown_small_width";
      }
      else {
        return "sort_dropdown school_filter_dropdown_large_width";
      }
    },
    usersSuccessfullyAssignedToTheStudentGy() {
      return store.state.assignUsersToStudentGYModule.usersSuccessfullyAssignedToTheStudentGy;
    }
  },
  mounted () {
    window.scrollTo(0, 0);
    let timeoutMilliseconds;
    if (this.schuelerSuccessfullyCreated === true) {
      timeoutMilliseconds = this.betreuungsfallAbgeschlossenValueWhenSavingStudent === 1 ? 30000 : 6000;
      setTimeout(() => {
        store.commit('schuelerModule/setSchuelerSuccessfullyCreated', false);
        store.commit('schuelerModule/setBetreuungsfallAbgeschlossenValueWhenSavingStudent', 0);
      }, timeoutMilliseconds);
    }
    if (this.schuelerSuccessfullyEdited === true) {
      timeoutMilliseconds = this.old_betreuungsfall_abgeschlossen === 0 && this.betreuungsfallAbgeschlossenValueWhenSavingStudent === 1 ? 30000 : 6000;
      setTimeout(() => {
        store.commit('schuelerModule/setSchuelerSuccessfullyEdited', false);
        store.commit('schuelerModule/setBetreuungsfallAbgeschlossenValueWhenSavingStudent', 0);
      }, timeoutMilliseconds);
    }
  },
  created() {
    store.dispatch('allSchuelerModule/setShowActiveCases', { showActiveCases: true });
    store.dispatch('allSchuelerModule/getAllSchueler');
    if (this.gyCanAssociateUsersToStudents) {
      // Get all users who can be assigned to students, because they are needed fully loaded in the created() of SchuelerUsersAssignGY.vue (FLOG)
      store.dispatch('allUsersModule/getAllUsersWhoCanBeAssignedToStudentsGy');
    }
  },
  updated() {
    if (!this.sortIconIsHidden && this.schoolFilter !== null){
      this.truncate(this.schoolFilter);
      // When the filter is removed, write "Filtern" in the dropdown element at the top (FLOG).
      if (this.schoolFilter === "Filter aufheben"){
        document.getElementById("school_filter_dropdown").getElementsByClassName("text")[0].textContent = "Filtern";
      }
    }
  },
  methods: {
    checkCanModifyStudentData: function(student = null) {
      return checkCanModifyStudentData(this.schoolType, this.userRole, this.userId, student);
    },
    clickSchoolFilterDropdown: function() {
      document.getElementById("school_filter_dropdown").click();
    },
    changeSchoolFilterDropdownStyle: function() {
      // Wrap long school names in the school filter dropdown when necessary and increase the width to better display long school names (FLOG).
      let schoolFilterDropdownItems = document.getElementById("school_filter_dropdown").getElementsByClassName("item");
      for(let i = (schoolFilterDropdownItems.length - 1); i >= 0; i--)
      {
        schoolFilterDropdownItems[i].style.whiteSpace = 'pre-line';
        schoolFilterDropdownItems[i].style.width = '20rem';
      }
      // Add a maximum height and a scroll bar to the dropdown selection menu, because otherwise you can't reach the school at the bottom if there are many schools.
      // Move the dropdown selection menu to the left so it doesn't slide off the screen (FLOG)
      let schoolFilterDropdownMenu = document.getElementById("school_filter_dropdown").getElementsByClassName("menu transition hidden")[0];
      if (schoolFilterDropdownMenu) {
        schoolFilterDropdownMenu.style.maxHeight = '31rem';
        schoolFilterDropdownMenu.style.overflowY = 'scroll';
        schoolFilterDropdownMenu.style.left = '-120px';
      }
    },
    closeAdminRightsOfOwnUserHaveBeenRemovedMessage: function () {
      store.dispatch('editUserModule/setAdminRightsOfOwnUserHaveBeenRemoved', { adminRightsOfOwnUserHaveBeenRemoved : false });
    },
    closeInfoAboutDocumentationModal: function () {
      store.commit('auth/setModalInfoAboutDocumentation', false);
    },
    closeRequestReturnedForbiddenResponseStatusMessage: function() {
      store.dispatch('config/setRequestReturnedForbiddenResponseStatus', { requestReturnedForbiddenResponseStatus : false });
    },
    closeSchuelerCreatedSuccessMessage: function () {
      store.commit('schuelerModule/setSchuelerSuccessfullyCreated', false);
      store.commit('schuelerModule/setBetreuungsfallAbgeschlossenValueWhenSavingStudent', 0);
    },
    closeSchuelerEditedSuccessMessage: function () {
      store.commit('schuelerModule/setSchuelerSuccessfullyEdited', false);
      store.commit('schuelerModule/setBetreuungsfallAbgeschlossenValueWhenSavingStudent', 0);
    },
    deleteSchueler: function(id) {
      this.modalDeleteSchueler = true;
      store.dispatch('schuelerModule/setSchuelerToDeleteId', { schueler_id: id });
    },
    deleteSchuelerYes: function() {
      this.modalDeleteSchueler = false;
      store.dispatch('schuelerModule/deleteSchueler')
      .then(() => {
      })
      .catch((error) => {
        if (error.response.status !== 403) {
          alert('Fehler beim Löschen der Schülerin / des Schülers. Bitte wenden Sie sich an den Administrator.');
        }
      });
    },
    deleteSchuelerNo: function() {
      this.modalDeleteSchueler = false;
      store.dispatch('schuelerModule/setSchuelerToDeleteId', { schueler_id: null });
    },
    formatUserRoleHeader(role) {
      switch(role) {
        case "MSD-MitarbeiterIn":
          return "MSD-MitarbeiterInnen";
        case "TherapeutIn":
          return "TherapeutInnen";
        default:
          return role;
      }
    },
    getBetreuungsaktFunctionFullName: function(string) {
      return getBetreuungsaktFunctionFullName(string);
    },
    getDropdownSortByUserText(sortOrder) {
      if (this.show_active_cases === true){
        return "Zugeordnete User " + sortOrder;
      }
      else {
        return "Zuletzt zugeordnete User " + sortOrder;
      }
    },
    isFirstOccurrenceOfUserRole(role) {
      if (userRolesThatAlreadyOccurred.has(role)) {
        return false;
      } else {
        userRolesThatAlreadyOccurred.add(role);
        return true;
      }
    },
    resetUserRolesThatAlreadyOccurred() {
      userRolesThatAlreadyOccurred.clear();
      // This function always returns false to prevent the div that is used to call this function from being rendered (FLOG)
      return false;
    },
    async sortAllSchueler (order, property1, property2, is_user) {
      const newPreferences = {
        sortOrder: order,
        propertyToSortBy1: property1,
        propertyToSortBy2: property2,
        isUser: is_user
      };
      await store.dispatch('allSchuelerModule/setSortPreferences', { component: this.$options.name, preferences: newPreferences });
      return store.getters['allSchuelerModule/stateSorted'](this.$options.name, 'schueler');
    },
    formatDate: function(isoDate) {
      return convertIsoDateToGermanDate(isoDate);
    },
    toggleHelpTextModal: function () {
      this.modalHelpText = !this.modalHelpText;
    },
    // Truncate school names as soon as they exceed the width of the dropdown element (FLOG)
    truncate: function(str) {
      // Get width of school_filter_dropdown
      let element = document.getElementById("school_filter_dropdown");
      var computedStyle = getComputedStyle(element);
      // width with padding
      let elementWidth = element.clientWidth;
      // width without padding
      elementWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);

      let strCharactersArray = Array.from(str);

      var str_concatenated = "";
      let fontFamily = computedStyle.getPropertyValue("font-family");

      var denominatorToDetermineMaximumWidth = 1.785;

      for (let i = 0; i < strCharactersArray.length; i++) {
        str_concatenated = str_concatenated+strCharactersArray[i];
        let str_width = this.getTextWidth(str_concatenated, fontFamily);

        // elementWidth/denominatorToDetermineMaximumWidth results in the maximum possible width without exceeding the border
        if (str_width > elementWidth/denominatorToDetermineMaximumWidth){
          var max_letter = i;
          break;
        }
      }

      let str_truncated;
      // Cut the school name at the maximum possible letter and insert '...' after the maximum possible letter (FLOG)
      str_truncated = (typeof max_letter !== 'undefined' && str.length > max_letter) ? str.slice(0, max_letter-1) + '...' : str;
      document.getElementById("school_filter_dropdown").getElementsByClassName("text")[0].textContent = str_truncated;
    },
    getTextWidth: function(text, font) {
      let canvas = this.getTextWidth.canvas || (this.getTextWidth.canvas = document.createElement("canvas"));
      let context = canvas.getContext("2d");
      context.font = font;
      let metrics = context.measureText(text);
      return metrics.width;
    },
    exportBetreuungsakt: function (event, schueler_id, schueler_name, schueler_vorname) {
      this.isAuthenticatedHauptverantwortlicheLkUserAssignedToExportBetreuungsaktStudent = false;
      store.dispatch('filesModule/checkForDuplicateFiles', { schueler_id: schueler_id, schueler_name: schueler_name, schueler_vorname: schueler_vorname})
        .then(() => {
          if (store.state.filesModule.pupilHasNoEntries) {
            this.pupilHasNoEntriesModalOpen = true;
          }
          else if (store.state.filesModule.categoriesWithDuplicateFileNames && Object.keys(store.state.filesModule.categoriesWithDuplicateFileNames).length) {
            let schuelerForExport = this.stateSorted.filter(obj => {
              return obj.id === schueler_id
            })
            schuelerForExport = schuelerForExport[0];
            if (isAuthenticatedHauptverantwortlicheLkUserIsAssignedToCurrentStudent(this.userRole, this.userId, schuelerForExport)){
              this.isAuthenticatedHauptverantwortlicheLkUserAssignedToExportBetreuungsaktStudent = true;
            }
            this.duplicateFileNamesModalOpen = true;
          }
          else {
            this.modalBetreuungsaktExportStarted = true;
          }
        })
        .catch((error) => {
          if (error.response.status !== 403) {
            alert('Fehler beim Erstellen der Betreuungsakt-Exportdatei. Bitte wenden Sie sich an die/den AdministratorIn.');
          }
        });
    },
    betreuungsaktExportStartedCancel: function() {
      this.modalBetreuungsaktExportStarted = false;
    },
    togglePupilHasNoEntriesModal: function () {
      this.pupilHasNoEntriesModalOpen = !this.pupilHasNoEntriesModalOpen;
    },
    toggleDuplicateFileNamesModal: function () {
      this.duplicateFileNamesModalOpen = !this.duplicateFileNamesModalOpen;
    },
    filterEntriesByStatus: function () {
      if (this.show_active_cases === true)
      {
        store.dispatch('allSchuelerModule/setShowActiveCases', { showActiveCases: false });
      }
      else
      {
        store.dispatch('allSchuelerModule/setShowActiveCases', { showActiveCases: true });
      }
    },
    expandOrCollapseAssociatedUsersOfOneStudent: function (clicked_entry_id) {
      let clicked_entry = this.schuelerSearchResult.find(obj => {return obj.id === clicked_entry_id});

      if (clicked_entry.expand_associated_users) {
        clicked_entry.expand_associated_users = !clicked_entry.expand_associated_users;
      }
      else {
        clicked_entry['expand_associated_users'] = true;
      }
    },
    expandAssociatedUsers: function () {
      this.schuelerSearchResult.forEach(function (e) {
        if (e.expand_associated_users) {
          e.expand_associated_users = true;
        }
        else {
          e['expand_associated_users'] = true;
        }
      })
    },
    collapseAssociatedUsers: function () {
      this.schuelerSearchResult.forEach(function (e) {
        if (e.expand_associated_users) {
          e.expand_associated_users = false;
        }
      })
    },
    closeUsersAssignedSuccessMessage: function () {
      store.dispatch('assignUsersToStudentGYModule/setUsersSuccessfullyAssignedToTheStudentGy', { usersSuccessfullyAssignedToTheStudentGy: false });
    }
  }
}
</script>

<style scoped>
#school_filter_icon {
  cursor: pointer;
  margin-right: 0.3em;
  color: rgba(0,0,0,.6);
  margin-left: 1em;
}
#school_filter_dropdown {
  padding-right: 0;
  margin-right: 0;
}
.school_filter_dropdown_small_width {
  width: 20%;
}
.school_filter_dropdown_large_width {
  width: 34%;
}
#angle_double_down_button {
  margin-right: 0 !important;
}
</style>
